/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
// import axios from "./axios.js";
import routerGuard from './router/routerGuard';
import RouteViewComponent from './router/routerView';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'main',
          meta: { url: '/', parent: '' },
          // redirect: "/pages/login"
          component: () => import('./views/pages/Main.vue'),
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('./views/pages/Main.vue'),
        },
        {
          path: '/example',
          name: 'example',
          component: () => import('@/views/example/index.vue'),
        },
        {
          path: '/dashboard/promo',
          name: 'promo',
          component: () => import('@/views/dashboard/Promo.vue'),
        },
        {
          path: '/dashboard/sales-achievement',
          name: 'sales-achievement',
          component: () => import('@/views/dashboard/SalesAchievement.vue'),
        },
        {
          path: '/dashboard/so-stages',
          name: 'so-stages',
          component: () => import('@/views/dashboard/SoStages/SoStages.vue'),
        },
        {
          path: '/dashboard/sopipodbill',
          name: 'sopipodbill',
          component: () => import('@/views/dashboard/Sopipodbill/Sopipodbill.vue'),
        },
        {
          path: '/report/po',
          name: 'report-po',
          component: () => import('@/views/report/PO/index.vue'),
        },
        {
          path: '/report/ap',
          name: 'report-ap',
          component: () => import('@/views/report/AP/index.vue'),
        },
        {
          path: '/report/p-consol',
          name: 'report-p-consol',
          component: () => import('@/views/report/PConsol/index.vue'),
        },
        {
          path: '/report/st-level',
          name: 'report-st-level',
          component: () => import('@/views/report/STLevel/index.vue'),
        },
        {
          path: '/report/ap-aging',
          name: 'report-ap-aging',
          component: () => import('@/views/report/APAging/index.vue'),
        },
        {
          path: '/report/ocr',
          name: 'report-ocr',
          component: () => import('@/views/report/OCR/index.vue'),
        },
        {
          path: '/report/st-movement',
          name: 'report-st-movement',
          component: () => import('@/views/report/STMovement/index.vue'),
        },
        {
          path: '/report/ar-aging',
          name: 'report-ar-aging',
          component: () => import('@/views/report/ARAging/index.vue'),
        },
        {
          path: '/report/st-posting-date',
          name: 'report-st-posting-date',
          component: () => import('@/views/report/STPostingDate/index.vue'),
        },
        {
          path: '/report/asset',
          name: 'asset',
          component: () => import('@/views/report/Asset/index.vue'),
        },
        {
          path: '/report/promotion',
          name: 'report-promotion',
          component: () => import('@/views/report/Promotion/index.vue'),
        },
        {
          path: '/report/cash-bank-management',
          name: 'report-cash-bank-management',
          component: () =>
            import('@/views/report/CashBankManagement/index.vue'),
        },
        {
          path: '/report/debit-note',
          name: 'report-debit-note',
          component: () => import('@/views/report/DebitNote/index.vue'),
        },
        {
          path: '/report/debit-note-claim-off-invoice',
          name: 'report-debit-note-claim-off-invoice',
          component: () => import('@/views/report/DebitNoteClaim/index.vue'),
        },
        {
          path: '/report/payment',
          name: 'report-payment',
          component: () => import('@/views/report/Payment/index.vue'),
        },
        {
          path: '/report/ar-trans',
          name: 'report-ar-trans',
          component: () => import('@/views/report/ARTrans/index.vue'),
        },
        {
          path: '/report/inbound',
          name: 'report-inbound',
          component: () => import('@/views/report/Inbound/index.vue'),
        },
        {
          path: '/report/credit-limit',
          name: 'report-credit-limit',
          component: () => import('@/views/report/CreditLimit/index.vue'),
        },
        {
          path: '/report/stock-opname',
          name: 'report-stock-opname',
          component: () => import('@/views/report/StockOpname/index.vue'),
        },
        {
          path: '/report/o2cflow',
          name: 'report-o2cflow',
          component: () => import('@/views/report/O2CFlow/index.vue'),
        },
        {
          path: "/report/map",
          name: "map",
          component: () => import("@/views/report/MAP/index.vue"),
        },
        {
          path: '/report/expired',
          name: 'expired',
          component: () => import('@/views/report/Expired/index.vue'),
        },
        {
          path: '/report/list-aging-product',
          name: 'list-aging-product',
          component: () => import('@/views/report/ListAgingProduct/index.vue'),
        },
        {
          path: '/report/item-special',
          name: 'item-special',
          component: () => import('@/views/report/ItemSpecial/index.vue'),
        },
        {
          path: '/report/shipment-planning-1',
          name: 'shipment-planning-1',
          component: () => import('@/views/report/ShipmentPlanning1/index.vue'),
        },
        {
          path: '/report/shipment-planning-2',
          name: 'shipment-planning-2',
          component: () => import('@/views/report/ShipmentPlanning2/index.vue'),
        },
        {
          path: '/report/journal-export',
          name: 'journal-export',
          component: () => import('@/views/report/JournalExport/index.vue'),
        },
        {
          path: '/report/journal-monitoring',
          name: 'journal-monitoring',
          component: () => import('@/views/report/JournalMonitoring/index.vue'),
        },
        {
          path: '/report/sales-order',
          name: 'sales-order',
          component: () => import('@/views/report/SalesOrder/index.vue'),
        },
        {
          path: '/report/bill-order',
          name: 'bill-order',
          component: () => import('@/views/report/BillOrder/index.vue'),
        },
        {
          path: "/report/inventory",
          name: "inventory",
          component: () => import("@/views/report/Inventory/index.vue"),
        },
        {
          path: "/report/stock-area",
          name: "stock-area",
          component: () => import("@/views/report/StockArea/index.vue"),
        },
        {
          path: "/report/stock-territory",
          name: "stock-territory",
          component: () => import("@/views/report/StockTerritory/index.vue"),
        },
        {
          path: "/report/purchase-order",
          name: "purchase-order",
          component: () => import("@/views/report/PurchaseOrder/index.vue"),
        },
        {
          path: "/report/scrap",
          name: "scrap",
          component: () => import("@/views/report/Scrap/index.vue"),
        },
        {
          path: "/report/sales-order-profitability",
          name: "sales-order-profitability",
          component: () => import("@/views/report/SalesOrderProfitability/index.vue"),
        },
        {
          path: '/report/sodogibill-export',
          name: 'report-sodogibill-export',
          component: () => import('@/views/report/SODOGIBILL/index.vue'),
        },
        {
          path: '/report/gr',
          name: 'report-gr',
          component: () => import('@/views/report/GR/index.vue'),
        },
        {
          path: '/report/p2p-flow-export',
          name: 'report-p2p-flow-export',
          component: () => import('@/views/report/P2PFlow/index.vue'),
        },
        {
          path: '/report/st-map-export',
          name: 'report-st-map-export',
          component: () => import('@/views/report/STMap/index.vue'),
        },
        {
          path: '/report/zdr-sales',
          name: 'report-zdr-sales',
          component: () => import('@/views/report/ZDRSales/index.vue'),
        },
        {
          path: '/report/zdr-bill',
          name: 'report-zdr-bill',
          component: () => import('@/views/report/ZDRBill/index.vue'),
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/version',
          name: 'version',
          component: () => import('@/views/pages/version.vue'),
        },
        {
          path: '/term-of-service',
          name: 'page-term-of-service',
          component: () => import('@/views/pages/TermOfService.vue'),
        },
        {
          path: '/pages/privacy-police',
          name: 'page-privacy-police',
          component: () => import('@/views/pages/PrivacyPolice.vue'),
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});
router.beforeEach(routerGuard);
export default router;
