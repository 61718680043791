import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        doc_date:
          x.doc_date == null ? '' : moment(x.doc_date).format('DD/MM/YYYY'),
        posting_date:
          x.posting_date == null
            ? ''
            : moment(x.posting_date).format('DD/MM/YYYY'),
        due_date:
          x.due_date == null ? '' : moment(x.due_date).format('DD/MM/YYYY'),
        fiscal_year:
          x.fiscal_year == null ? '' : moment(x.fiscal_year).format('YYYY'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getARAgingReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/report/ar-aging-report`, { params: payload })
      .then(
        (result) => {
          commit("UPDATE_TABLE_DATA", result);
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  generateARAgingReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/ar-aging-report`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  exportARAgingReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  downloadARAgingReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
