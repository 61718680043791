
import api from '@/axios.js';

const state = {
    baseUrlPath: '/api/report/v1',
    dataList: [],
    total_page: 0,
    total_record: 0,
    total_record_per_page: 0,
    total_record_search: 0,
    assetNumberList: [],
    assetClassList: [],
    costCenterList: [],
    generateReport: null,
    exportReport: null,
    exportReportTotalPage: 1,
    downloadReport: null,
}

const mutations = {
    MAP_DATA: (state, val) => {
        state.dataList = val.data.records
        state.total_page = val.data.total_page
        state.total_record = val.data.total_record
        state.total_record_per_page = val.data.total_record_per_page
        state.total_record_search = val.data.total_record_search
    },
    MAP_ASSET_NUMBER: (state, val) => {
        state.assetNumberList = val.data.records      
    },
    MAP_ASSET_CLASS: (state, val) => {
        state.assetClassList = val.data.records      
    },
    MAP_COST_CENTER: (state, val) => {
        state.costCenterList = val.data.records      
    },
    GENERATE_REPORT(state, val) {
        state.generateReport = val;
    },
    EXPORT_REPORT(state, val) {
        const data = val.data.records.map((el) => {
          return {
            ...el,
            file_path: el.file_name,
          };
        });
        state.exportReport = data;
        state.exportReportTotalPage = val.data.total_page;
    },
    DOWNLOAD_REPORT(state, val) {
        state.downloadReport = val;
    },
}

const actions = {
    getAssetReport({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/report/asset-report`, { params: payload }).then(
            (result) => {
                commit('MAP_DATA', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },

    getAssetNumber({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/asset-number`, { params: payload }).then(
            (result) => {
                commit('MAP_ASSET_NUMBER', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },

    getAssetClass({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/asset-class`, { params: payload }).then(
            (result) => {
                commit('MAP_ASSET_CLASS', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },

    getCostCenter({ state, commit }, payload) {
        api.get(`${state.baseUrlPath}/master/cost-center`, { params: payload }).then(
            (result) => {
                commit('MAP_COST_CENTER', result);
                return result;
            },
            () => {
                return false;
            }
        );
    },
    generateAssetReport({ state, commit }, payload) {
        api
          .get(
            `${state.baseUrlPath}/download-report/asset-report`,
            { params: payload }
          )
          .then((result) => {
            commit("GENERATE_REPORT", result);
            return result;
          })
          .catch((error) => {
            return error;
          });
    },
    exportAssetReport({ state, commit }, payload) {
        api
          .get(`${state.baseUrlPath}/download-report/export-excel`, {
            params: payload,
          })
          .then((result) => {
            commit("EXPORT_REPORT", result);
            return result;
          })
          .catch((error) => {
            return error;
          });
    },
    downloadAssetReport({ state, commit }, payload) {
        api
          .get(`${state.baseUrlPath}/download-report/download`, {
            headers: {
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: "blob",
            params: payload,
          })
          .then((result) => {
            const data = {
              data: result,
              name: payload.file_name,
            };
            commit("DOWNLOAD_REPORT", data);
            return data;
          })
          .catch((error) => {
            return error;
          });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
