import axios from "@/axios.js";

const state = {
  baseUrlPath: "/api/report/v1",
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
  shipmentNoList: [],
  piNoList: [],
  shipmentCostNoList: [],
  fwdAgentNoList: [],
  downloadReport: null,
  exportReport: null,
  generateReport: null,
  exportReportTotalPage: 1,
};

const mutations = {
  MAP_DATA(state, val) {
    state.tableData = val.data.records;
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
  MAP_SHIPMENT(state, val) {
    state.shipmentNoList = val.data.records;
  },
  MAP_PI(state, val) {
    state.piNoList = val.data.records;
  },
  MAP_SHIPMENT_COST(state, val) {
    state.shipmentCostNoList = val.data.records;
  },
  MAP_FWD_AGENT(state, val) {
    state.fwdAgentNoList = val.data.records;
  },
  DOWNLOAD_REPORT(state, val) {
    state.downloadReport = val;
  },
  EXPORT_REPORT(state, val) {
    const data = val.data.records.map((el) => {
      return {
        ...el,
        file_path: el.file_name,
      };
    });
    state.exportReport = data;
    state.exportReportTotalPage = val.data.total_page;
  },
  GENERATE_REPORT(state, val) {
    state.generateReport = val;
  },
};

const actions = {
  getShipmentPlanningReport({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/report/sdn-report-shipment-planning`, {
        params: payload,
      })
      .then((result) => {
        commit("MAP_DATA", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  getShipmentNo({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/master/shipment-no`, { params: payload })
      .then((result) => {
        commit("MAP_SHIPMENT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  getPiNo({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/master/pi-no`, { params: payload })
      .then((result) => {
        commit("MAP_PI", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  getShipmentCostNo({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/master/shipment-cost-no`, { params: payload })
      .then((result) => {
        commit("MAP_SHIPMENT_COST", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  getFwdAgentNo({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/master/fwd-agent-no`, { params: payload })
      .then((result) => {
        commit("MAP_FWD_AGENT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  downloadShipmentPlanningReport({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then((result) => {
        const data = {
          data: result,
          name: payload.file_name,
        };
        commit("DOWNLOAD_REPORT", data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  },
  exportShipmentPlanningReport({ state, commit }, payload) {
    axios
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then((result) => {
        commit("EXPORT_REPORT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  generateShipmentPlanningReport({ state, commit }, payload) {
    axios
      .get(
        `${state.baseUrlPath}/download-report/sdn-report-shipment-planning`,
        { params: payload }
      )
      .then((result) => {
        commit("GENERATE_REPORT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
