/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import moduleAuth from './auth/moduleAuth.js';

// per module
import user from './modules/user';
import master from './modules/master';
import outbound from './modules/outbound';
import inbound from './modules/inbound';
import purchaseOrder from './modules/purchase-order';
import purchase from './modules/purchase';
import logImport from './modules/import-log';
import log from './modules/log';
import reportAPModule from './modules/report/reportAP.module.js';
import reportPOModule from './modules/report/reportPO.module.js';
import reportPConsolModule from './modules/report/reportPConsol.module.js';
import reportSTLevelModule from './modules/report/reportSTLevel.module.js';
import reportAPAgingModule from './modules/report/reportAPAging.module.js';
import reportOCRModule from './modules/report/reportOCR.module.js';
import reportSTPostingDateModule from './modules/report/reportSTPostingDate.module.js';
import reportSTMovementModule from './modules/report/reportSTMovement.module.js';
import reportARAgingModule from './modules/report/reportARAging.module.js';
import reportAssetModule from './modules/report/reportAsset.module.js';
import reportPromotion from './modules/report/reportPromotion.module.js';
import reportCashModule from './modules/report/reportCash.module.js';
import reportDebitNoteModule from './modules/report/reportDebitNote.module.js';
import reportDebitNoteClaimModule from './modules/report/reportDebitNoteClaim.module.js';
import reportPaymentModule from './modules/report/reportPayment.module.js';
import reportARTransModule from './modules/report/reportARTrans.module.js';
import reportInboundModule from './modules/report/reportInbound.module.js';
import reportCreditLimitModule from './modules/report/reportCreditLimit.module.js';
import reportStockOpnameModule from './modules/report/reportStockOpname.module.js';
import reportO2CFlowModule from './modules/report/reportO2CFlow.module.js';
import reportMAPModule from "./modules/report/reportMAP.module.js";
import reportExpiredModule from './modules/report/reportExpired.module.js';
import reportAgingProductModule from './modules/report/reportAgingProduct.module.js';
import reportItemSpecialModule from './modules/report/reportItemSpecial.module.js';
import reportShipmentPlanningModule from './modules/report/reportShipmentPlanning.module.js';
import reportJournalExportModule from './modules/report/reportJournalExport.module.js'
import reportJournalMonitoringModule from './modules/report/reportJournalMonitoring.module.js'
import reportSalesorderModule from './modules/report/reportSalesOrder.module.js'
import reportBillOrderModule from './modules/report/reportBillOrder.module.js';
import reportShipmentPlanning2Module from './modules/report/reportShipmentPlanning2.module.js';
import soStagesModule from './modules/dashboard/soStages.module.js';
import sopipodbillModule from './modules/dashboard/sopipodbill.module.js';
import reportInventoryModule from './modules/report/reportInventory.module.js';
import reportStockAreaModule from './modules/report/reportStockArea.module.js';
import reportStockTerritoryModule from './modules/report/reportStockTerritory.module.js';
import reportPurchaseOrderModule from './modules/report/reportPurchaseOrder.module.js';
import reportScrapModule from './modules/report/reportScrap.module.js';
import reportSalesOrderProfitabilityModule from './modules/report/reportSalesOrderProfitability.module.js';
import reportSODOGIBILLModule from './modules/report/reportSODOGIBILL.js';
import reportGRModule from './modules/report/reportGR.module.js';
import reportP2PFlowModule from './modules/report/reportP2PFlow.module.js';
import reportStMAPModule from './modules/report/reportStMAP.module.js';
import reportZdrSalesModule from './modules/report/reportZdrSales.module.js';
import reportZDRBillModule from './modules/report/reportZDRBill.module.js'

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    user: user,
    master: master,
    outbound: outbound,
    inbound: inbound,
    purchaseOrder: purchaseOrder,
    auth: moduleAuth,
    purchase: purchase,
    logImport: logImport,
    log: log,
    reportAP: reportAPModule,
    reportPO: reportPOModule,
    reportPConsol: reportPConsolModule,
    reportSTLevel: reportSTLevelModule,
    reportAPAging: reportAPAgingModule,
    reportOCR: reportOCRModule,
    reportSTPostingDate: reportSTPostingDateModule,
    reportSTMovement: reportSTMovementModule,
    reportARAging: reportARAgingModule,
    reportAsset: reportAssetModule,
    reportPromotion: reportPromotion,
    reportCash: reportCashModule,
    reportDebitNote: reportDebitNoteModule,
    reportDebitNoteClaim: reportDebitNoteClaimModule,
    reportPayment: reportPaymentModule,
    reportARTrans: reportARTransModule,
    reportInbound: reportInboundModule,
    reportCreditLimit: reportCreditLimitModule,
    reportStockOpname: reportStockOpnameModule,
    reportO2CFlow: reportO2CFlowModule,
    reportMAP: reportMAPModule,
    reportExpired: reportExpiredModule,
    reportAgingProduct: reportAgingProductModule,
    reportItemSpecial: reportItemSpecialModule,
    reportShipmentPlanning: reportShipmentPlanningModule,
    reportJournalExport: reportJournalExportModule,
    reportJournalMonitoring: reportJournalMonitoringModule,
    reportSalesOrder: reportSalesorderModule,
    reportBillOrder: reportBillOrderModule,
    reportShipmentPlanning2: reportShipmentPlanning2Module,
    soStages: soStagesModule,
    sopipodbill: sopipodbillModule,
    reportInventory: reportInventoryModule,
    reportStockArea: reportStockAreaModule,
    reportStockTerritory: reportStockTerritoryModule,
    reportPurchaseOrder: reportPurchaseOrderModule,
    reportScrap: reportScrapModule,
    reportSalesOrderProfitability: reportSalesOrderProfitabilityModule,
    reportSODOGIBILL: reportSODOGIBILLModule,
    reportGR: reportGRModule,
    reportP2PFlow: reportP2PFlowModule,
    reportStMAP: reportStMAPModule,
    reportZdrSalesModule: reportZdrSalesModule,
    reportZdrBillModule: reportZDRBillModule
  },
  strict: process.env.NODE_ENV !== 'production',
});
