import api from "@/axios.js";

const state = {
  baseUrlPath: "/api/report/v1",
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
  generateReport: null,
  exportReport: null,
  exportReportTotalPage: 1,
  downloadReport: null,
};

const mutations = {
  MAP_DATA(state, val) {
    state.tableData = val.data.records;
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
  GENERATE_REPORT(state, val) {
    state.generateReport = val;
  },
  EXPORT_REPORT(state, val) {
    const data = val.data.records.map((el) => {
      return {
        ...el,
        file_path: el.file_name,
      };
    });
    state.exportReport = data;
    state.exportReportTotalPage = val.data.total_page;
  },
  DOWNLOAD_REPORT(state, val) {
    state.downloadReport = val;
  },
};

const actions = {
  getSTMAPReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/report/st-map-export`, { params: payload })
      .then(
        (result) => {
          commit("MAP_DATA", result);
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  generateMapReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/download-report/st-map-export`, {
        params: payload,
      })
      .then((result) => {
        commit("GENERATE_REPORT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  exportMapReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then((result) => {
        commit("EXPORT_REPORT", result);
        return result;
      })
      .catch((error) => {
        return error;
      });
  },
  downloadMapReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then((result) => {
        const data = {
          data: result,
          name: payload.file_name,
        };
        commit("DOWNLOAD_REPORT", data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
