import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        doc_date:
          x.doc_date == null ? '' : moment(x.doc_date).format('DD/MM/YYYY'),
        po_date:
          x.po_date == null ? '' : moment(x.po_date).format('DD/MM/YYYY'),
        delivery_date:
          x.delivery_date == null
            ? ''
            : moment(x.delivery_date).format('DD/MM/YYYY'),
        latest_gi_gr_posting_date:
          x.latest_gi_gr_posting_date == null
            ? ''
            : moment(x.latest_gi_gr_posting_date).format('DD/MM/YYYY'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getPConsolReport({ state, commit }, payload) {
    api
      .get(`${state.baseUrlPath}/report/pconsol-export`, { params: payload })
      .then(
        (result) => {
          commit("UPDATE_TABLE_DATA", result);
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  generatePConsolReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/pconsol-export`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  exportPConsolReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  downloadPConsolReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
