import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        document_date: moment(x.document_date).format('DD/MM/YYYY'),
        fiscal_year: moment(x.fiscal_year).format('YYYY'),
        entry_date: moment(x.entry_date).format('DD/MM/YYYY'),
        posting_date: moment(x.posting_date).format('DD/MM/YYYY'),
        net_due_date: moment(x.net_due_date).format('DD/MM/YYYY'),
        time_of_entry: moment(x.time_of_entry).format('HH:mm:ss'),
        year_month: moment(x.year_month).format('YYYYMM'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getAPReport({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/report/ap-export`, { params: payload }).then(
      (result) => {
        commit("UPDATE_TABLE_DATA", result);
        return result;
      },
      (error) => {
        return false;
      }
    );
  },
  generateAPReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/ap-export`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  exportAPReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  downloadAPReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
