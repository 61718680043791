import api from '@/axios.js';
import moment from 'moment';

const state = {
  baseUrlPath: '/api/report/v1',
  tableData: [],
  total: 0,
  totalPerPage: 10,
  totalPage: 1,
  totalSearch: 10,
};

const mutations = {
  UPDATE_TABLE_DATA(state, val) {
    state.tableData = val.data.records.map((x) => {
      return {
        ...x,
        date: moment(x.date).format('DD/MM/YYYY'),
        so_date: moment(x.so_date).format('DD/MM/YYYY'),
      };
    });
    state.total = val.data.total_record;
    state.totalPerPage = val.data.total_record_per_page;
    state.totalPage = val.data.total_page;
    state.totalSearch = val.data.total_record_search;
  },
};

const actions = {
  getPromotionReport({ state, commit }, payload) {
    api.get(`${state.baseUrlPath}/report/promotion`, { params: payload }).then(
      (result) => {
        commit("UPDATE_TABLE_DATA", result);
        return result;
      },
      (error) => {
        return false;
      }
    );
  },
  generatePromotionReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/promotion`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  exportPromotionReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/export-excel`, {
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
  downloadPromotionReport({ state }, payload) {
    return api
      .get(`${state.baseUrlPath}/download-report/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: payload,
      })
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return false;
        }
      );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
